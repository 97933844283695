.CategoriesSlider{
    padding: 10px;

}
.CategoriesSlider p{
    padding: 15px;
}
.scroll-btn{
    background-color: transparent;
    border: none;
}
.fixed{
    background-color: var(--body-bg);
    position: fixed;
    z-index: 8;
    padding: 12px 0px;
    top: 56px;
    right: 0;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
}